import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Os nossos serviços | Serviços na Gearhead Auto Service
			</title>
			<meta name={"description"} content={"Cuidados especializados para cada carro - Gearhead Auto Service oferece excelência\n"} />
			<meta property={"og:title"} content={"Os nossos serviços | Serviços na Gearhead Auto Service"} />
			<meta property={"og:description"} content={"Cuidados especializados para cada carro - Gearhead Auto Service oferece excelência\n"} />
			<meta property={"og:image"} content={"https://crandilates.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://crandilates.com/img/1571979.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://crandilates.com/img/1571979.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://crandilates.com/img/1571979.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://crandilates.com/img/1571979.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://crandilates.com/img/1571979.png"} />
			<meta name={"msapplication-TileImage"} content={"https://crandilates.com/img/1571979.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Text
				margin="0px 0px 90px 0px"
				font="normal 600 42px/1.2 --fontFamily-sans"
				color="--darkL1"
				border-color="--color-dark"
				text-align="center"
				lg-margin="0px 0px 50px 0px"
			>
				Os nossos serviços
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Na Gearhead Auto Service, oferecemos uma gama abrangente de serviços concebidos para satisfazer todas as suas necessidades automóveis. A nossa equipa de técnicos especializados está equipada para tratar de tudo, desde a manutenção de rotina a reparações complexas, garantindo que o seu veículo está nas melhores condições.
					</Text>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Manutenção geral
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						- Mudanças de óleo: Essencial para a saúde do motor, utilizando óleos de alta qualidade para um desempenho ótimo.
						<br />
						<br />
						- Serviços de travões: Manutenção e reparação abrangentes dos travões para uma condução segura.
					</Text>
				</Box>
				<Image src="https://crandilates.com/img/7.jpg" display="block" width="100%" md-order="-1" />
				<Image src="https://crandilates.com/img/6.jpg" display="block" width="100%" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Serviços de reparação
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						- Diagnóstico e reparação de motores: Utilização de ferramentas de diagnóstico avançadas para identificar e reparar problemas do motor.
						<br />
						<br />
						- Serviços de transmissão: Especialista em reparações e manutenção de transmissões manuais e automáticas.
					</Text>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Serviços especializados
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						- Sistemas eléctricos: Desde verificações de baterias a reparações eléctricas complexas.
						<br />
						<br />
						- Suspensão e direção: Assegurando uma experiência de condução suave e controlada.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="120px 0 130px 0" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-4">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap={0}
				lg-padding="0px 0 0px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 34px 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Personalização e actualizações
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							- Afinação de desempenho: Aumente o desempenho do seu veículo com os nossos serviços de afinação especializados.
							<br />
							<br />
							- Modificações personalizadas: Personalize o seu veículo com modificações personalizadas, feitas à medida das suas especificações.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 34px 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Inspecções de segurança e pré-compra
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							- Inspecções detalhadas de veículos: Inspecções minuciosas para tranquilidade, quer esteja a comprar ou a garantir a segurança do veículo.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 0 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Serviços de emergência
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							- Assistência na estrada: Ajuda rápida e fiável quando mais precisa.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});